<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <div class="card card-body dd">
            <div v-if="step == 1">
                <h4 class="text-center">
                    تأكيد ارسال الغياب والتأخر والحضور للطلاب
                    <br>
                    <div style="padding: 5px !important">
                        بتاريخ {{ date }}
                    </div>
                </h4>
                <div class="col-12">
                    <div class="row">
                        <div class="col-12 g">
                            <hr>
                            <b-form-checkbox
                                v-model="sendto"
                                value="1"
                            >
                                ارسال الحضور
                            </b-form-checkbox>
                            <b-form-checkbox
                                v-model="sendto"
                                value="2"
                            >
                                ارسال التأخر
                            </b-form-checkbox>
                            <b-form-checkbox
                                v-model="sendto"
                                value="3"
                            >
                                ارسال الغياب
                            </b-form-checkbox>
                            <b-form-checkbox
                                v-model="sendto"
                                value="5"
                            >
                                ارسال الغياب بعذر
                            </b-form-checkbox>
                            <b-form-checkbox
                                v-model="sendto"
                                value="ex"
                            >
                                ارسال الاستئذان
                            </b-form-checkbox>
                        </div>
                        <div class="col-12 g">
                        <hr>
                            <div class="row">
                                <div class="col-6" v-for="c in classrooms" :key="c.id">
                                    <b-form-checkbox
                                        v-model="selected_classrooms"
                                        :value="c.id"
                                    >
                                        {{ c.title }}
                                    </b-form-checkbox>
                                </div>
                            </div>
                            <br>
                        <small class="g"><a href="javascript:;" @click="clearClasses()"><i class="fa fa-times"></i> الغاء اختيار الكل</a></small>
                        </div>
                    </div>
                </div>
                <div class="col-12 text-center">
                    <button class="btn btn-primary" @click="step = 2">
                        التالي <i class="fa fa-arrow-left"></i>
                    </button>
                </div>
            </div>
            <div class="col-12 table-responsive" v-if="step == 2">
                <div class="col-12 text-center g">
                    <div class="g">
                        <div class="form-check">
                            <label class="form-check-label">
                            <input type="checkbox" class="form-check-input" v-model="sendex" value="true">
                            هل تريد اضافة رابط للرد على الغياب؟
                            </label>
                        </div>
                        <br>
                    </div>
                    <button class="btn btn-success" @click="step = 3">
                        معاينة والارسال
                        <i class="fa fa-arrow-left"></i>
                    </button>
                    <br>
                    <button class="btn btn-default border btn-sm" style="margin-top: 5px" v-b-modal.messages>
                        <i class="fa fa-comment"></i>
                        التحكم في الرسائل
                    </button>
                    <br>
                </div>
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>
                            الطالب
                        </th>
                        <th>
                            الفصل
                        </th>
                        <th>
                            الحالة
                        </th>
                    </thead>
                    <tbody>
                        <template v-for="report in reports">
                            <tr v-if="sendto.includes(report.status.toString()) && selected_classrooms.includes(report.classname +'-'+ report.classroom)" :key="report.name">
                                <td>
                                    {{ report.name }}
                                </td>
                                <td>
                                    {{ report.classname }}
                                    -
                                    {{ report.classroom }}
                                </td>
                                <td>
                                    <span class="badge btn-sm bg-success" v-if="report.status == '1'">
                                        <i class="fa fa-check"></i> حاضر
                                    </span>
                                    <span class="badge btn-sm bg-warning" v-if="report.status == '2'">
                                        <i class="fa fa-clock-o"></i> متأخر
                                    </span>
                                    <span class="badge btn-sm bg-danger" v-if="report.status == '3'">
                                        <i class="fa fa-times"></i> غائب
                                    </span>
                                    <span class="badge btn-sm bg-info" v-if="report.status == '5'">
                                        <i class="fa fa-times"></i> غائب بعذر
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="col-12" v-if="step == 3">
                <div class="row">
                    <div class="col-12">
                        ارسال عبر:
                    </div>
                    <div class="col-12 col-lg-4 g">
                        <button class="btn btn-success btn-block"  @click="send('whatsapp')">
                            <i class="fa fa-whatsapp"></i>
                            واتس تلقائي
                        </button>
                    </div>
                    <div class="col-12 col-lg-4 g">
                        <button class="btn btn-success btn-block" @click="send('wa')">
                            <i class="fa fa-whatsapp"></i>
                            اداة المتصفح
                        </button>
                    </div>
                    <div class="col-12 col-lg-4 g">
                        <button class="btn btn-primary btn-block"  @click="send('sms')">
                            <i class="fa fa-mobile"></i>
                            SMS
                        </button>
                    </div>
                </div>
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>
                            الطالب
                        </th>
                        <th>
                            الجوال
                        </th>
                        <th>
                            الرسالة
                        </th>
                    </thead>
                    <tbody>
                        <template v-for="report in reports">
                            <tr v-if="sendto.includes(report.status.toString()) && selected_classrooms.includes(report.classname +'-'+ report.classroom)" :key="report.name">
                                <td>
                                    {{ report.name }}
                                </td>
                                <td>
                                    <span v-if="students[report.number]">
                                        {{ students[report.number].phones.join(',') }}
                                    </span>
                                </td>
                                <td>
                                    <textarea readonly rows="3" class="form-control">{{ generateMessage(report) }}</textarea>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
            <div class="col-12 text-center" v-if="step == 4">
                <div class="alert alert-success g">
                    تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل
                </div>
            </div>
        </div>
    </div>
    <b-modal id="messages" title="الرسائل" hide-footer>
        <div class="form-group">
          <label for="">رسالة الحضور</label>
          <textarea class="form-control" v-model="options.attend_message" rows="3"></textarea>
        </div>
        <div class="form-group">
          <label for="">رسالة التأخر</label>
          <textarea class="form-control" v-model="options.late_message" rows="3"></textarea>
        </div>
        <div class="form-group">
          <label for="">رسالة الغياب</label>
          <textarea class="form-control" v-model="options.ghiab_message" rows="3"></textarea>
        </div>
        <div class="form-group">
          <label for="">رسالة الاستئذان</label>
          <textarea class="form-control" v-model="options.ex_message" rows="3"></textarea>
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="saveMessages()">
                <i class="fa fa-save"></i>
                حفظ الرسائل
            </button>
        </div>
    </b-modal>
  </div>
</template>

<script>
import { BFormCheckbox, VBModal, BModal, } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BFormCheckbox,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            date: window.location.href.split("-send/")[1],
            reports: [],
            user: JSON.parse(localStorage.getItem('user')),
            sendto: [],
            classrooms: [],
            selected_classrooms: [],
            sendex: false,
            step: 1,
            options: {},
            students: {}
        }
    },
    created(){
        var g = this;
        if(!checkPer("students|students|send")){
            this.$router.push('/NotPermitted')
            return false;
        }
        g.getReport()
        if(window.location.href.includes("status=")){
            if(window.location.href.split("status=")[1].includes("1")){
                g.sendto.push('1');
            }
            if(window.location.href.split("status=")[1].includes("2")){
                g.sendto.push('2');
            }
            if(window.location.href.split("status=")[1].includes("3")){
                g.sendto.push('3');
                g.sendto.push('5');
            }
        }else{
            g.sendto = ["1","2","3","5","ex"]
        }
        $.post(api + '/user/students/send-settings', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.options = r.response;

            }
        })
        $.post(api + '/user/students/list', {
            jwt: this.user.jwt,
            q: this.q,
            page: 1,
            perpage: 10000
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                r.response.forEach(function(a){
                    g.students[a.number] = a;
                })
            }
        }).fail(function(){
            g.loading = false;
        })
    },
    methods: {
        getReport(){
            var g = this;
            $.post(api + '/user/students/reports/daily', {
                jwt: this.user.jwt,
                date: window.location.href.split("-send/")[1]
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.reports = r.response
                    g.reports.forEach(function(a){
                        if(!g.selected_classrooms.includes(a.classname + '-' + a.classroom)){
                            g.classrooms.push({
                                title: a.classname + ' - ' + a.classroom,
                                id: a.classname + '-' + a.classroom
                            })
                            g.selected_classrooms.push(a.classname + '-' + a.classroom)
                        }
                    })
                }
            }).fail(function(){
            })
        },
        send(method){
            var g = this;
            g.sendto = JSON.parse(JSON.stringify(g.sendto))
            var arr = g.reports.filter(function(x){
                if(x.leave && g.sendto.includes("ex") && g.selected_classrooms.includes(x.classname + '-' + x.classroom)){
                    if(x.leave.endex == true){
                        return x;
                    }
                }
                if(g.sendto.includes(x.status.toString()) && g.selected_classrooms.includes(x.classname + '-' + x.classroom)){
                    return x;
                }else{
                    return false;
                }
            })
            var messages = [];
            arr.forEach(function(a){
                messages.push({
                    phones: g.students[a.number].phones,
                    message: g.generateMessage(a)
                })
            })
            if(g.sendex){
                $.post(api + '/user/general/short-links', {
                jwt: this.user.jwt,
                arr: JSON.stringify(messages),
            }).then(function(rx){
                if(method == 'wa'){
                    $("#sendmodalwaarray").val(JSON.stringify(rx.response))
                    $("#sendmodalwatype").val("students")
                    $("#sendWAModal").click()
                }else{
                    if(!confirm(`متأكد من ارسال ${arr.length} رسالة؟`)){return false;}
                    $.post(api + '/user/messages/send-arr', {
                        jwt: g.user.jwt,
                        arr: JSON.stringify(rx.response),
                        method: method,
                        type: "students"
                    }).then(function(r){
                        g.loaded = true;
                        if(r.status != 100){
                            alert(r.response, 200)
                        }else{
                            alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
                            g.step = 4
                        }
                    }).fail(function(){
                        alert("حدث خطأ", 200)
                    })
                }
            }).fail(function(){
                alert("حدث خطأ.", 200)
            })
            }else{
                if(method == 'wa'){
                    $("#sendmodalwaarray").val(JSON.stringify(messages))
                    $("#sendmodalwatype").val("students")
                    $("#sendWAModal").click()
                }else{
                    if(!confirm(`متأكد من ارسال ${arr.length} رسالة؟`)){return false;}
                    $.post(api + '/user/messages/send-arr', {
                        jwt: g.user.jwt,
                        arr: JSON.stringify(messages),
                        method: method,
                        type: "students"
                    }).then(function(r){
                        g.loaded = true;
                        if(r.status != 100){
                            alert(r.response, 200)
                        }else{
                            alert("تم الارسال بنجاح٫ يمكنك تصفح ارشيف الرسائل", 100)
                            g.step = 4
                        }
                    }).fail(function(){
                        alert("حدث خطأ", 200)
                    })
                }
            }
        },
        clearClasses(){
            this.selected_classrooms = []
        },
        saveMessages(){
            var g = this;
            $.post(api + '/user/students/save-send-messages', {
                jwt: this.user.jwt,
                late_message: g.options.late_message ?? "",
                attend_message: g.options.attend_message ?? "",
                ghiab_message: g.options.ghiab_message ?? "",
                ex_message: g.options.ex_message ?? "",
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    $("#messages___BV_modal_header_ > button").click()
                }
            }).fail(function(){
                alert("حدث خطأ في الاتصال", 200)
            })
        },
        generateMessage(report){
            var g = this;
            var message = "";
            if(report?.status == 1){
                message = g.options?.attend_message;
            }
            if(report?.status == 2){
                message = g?.options?.late_message;
            }
            if(report?.status == 3){
                message = g?.options?.ghiab_message;
            }
            if(report?.status == 5){
                message = `ابنكم {name} غائب بعذر اليوم بتاريخ ` + g.date;
            }

            if(report.leave && g.sendto.includes("ex")){
                if(report.leave.endex == true){
                    message =  g?.options?.ex_message;
                }
            }
            if(g.sendex && report.status == 3){
                var link = window.location.href.split("/_s")[0] + "/_students/reply-to-ghiab/" + btoa(g.date + '/' + g.students[report.number]?._id);
            }
            message = message.replace("{name}", report?.name).replace("{time}", report.status == '3' || report.status == 3 ? g.date : (report?.join?.time ?? g.date)) + (g.sendex && (report.status == 3) ? '\nبمكنك الرد على الغياب من هنا:\n' + `({${link}})` : '')
            return message;
        }
    }
}
</script>

<style>

</style>